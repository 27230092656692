<template>
  <div class="wrapper new-announcement" ref="page">
    <ctheader />
    <div class="content">
      <div class="form">
        <div class="field">
          <label>标题</label>
          <input type="text" v-model="title" maxlength="30" />
        </div>
        <div class="field">
          <label>发送</label>
          <radio
            :radioData="customerTypeList"
            radioName="customerType"
            :radioCheckedId="customerType"
          ></radio>
          <template v-if="customerType === 2">
            <a @click="choose" class="desc mgl-20 mgr-5">选择客户</a>
            <span class="desc">已选{{ hq_codes.length }}项</span>
          </template>
        </div>
        <div class="field">
          <label>类型</label>
          <radio
            :radioData="typeList"
            radioName="type"
            :radioCheckedId="type"
          ></radio>
        </div>
        <div class="field">
          <label>详情</label>
          <div class="editor">
            <vue-ueditor-wrap
              v-model="content"
              :config="editorConfig"
              :destroy="true"
            ></vue-ueditor-wrap>
          </div>
        </div>
        <div class="field">
          <label></label>
          <div class="btn-group">
            <button class="btn primary" @click="save(1)">提交发送</button>
            <button class="btn secondary" @click="save(0)">保存草稿</button>
            <button class="btn secondary" @click="cancel">取消</button>
          </div>
        </div>
      </div>
    </div>
    <layers
      :show="isShow"
      title="选择客户"
      :width="800"
      :saveCallBack="saveLayer"
      :closeCallBack="cancelLayer"
    >
      <div class="layer-container">
        <div class="native-table">
          <table>
            <thead>
              <tr>
                <th>
                  <i
                    class="sn-icon"
                    :class="{
                      'sdnm-square_selected': isCheckAll,
                      'sdnm-square_not_selected': !isCheckAll,
                    }"
                    @click="checkAll"
                    data-level="1"
                  ></i>
                </th>
                <th>客户编号</th>
                <th>客户名称</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in customerList">
                <td>
                  <i
                    class="sn-icon"
                    :class="{
                      'sdnm-square_selected': row.checked,
                      'sdnm-square_not_selected': !row.checked,
                    }"
                    @click="check(index)"
                  ></i>
                </td>
                <td>
                  <div class="table-span">{{ row.code }}</div>
                </td>
                <td>{{ row.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </layers>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
// import ctheader from '@@/ctheader';
import VueUeditorWrap from "vue-ueditor-wrap";
import { apiPost } from "../../modules/utils";
import APIs from "../../modules/APIs";
// import router from '@/router';
import * as URL from "../../modules/URLs";
// import radio from '@@/radio';
// import layers from '@@/layers';
import router from "../../router";
import ctheader from "../../components/ctheader";
import radio from "../../components/radio";
import layers from "../../components/layers";
import { event } from "../../modules/EVENTs";

const getters = mapGetters([]);

const actions = mapActions(["setAlert"]);

/**
 * @module index.vue
 * @desc this is module index.vue
 * @author songkexin <songkexin@rongyi.com>
 * @since 2021-04-09
 * @copyright 2021
 */
export default {
  name: "announcement",
  components: {
    ctheader,
    VueUeditorWrap,
    radio,
    layers,
  },
  computed: {
    checkedList() {
      return this.customerList.filter((el) => el.checked).map((el) => el.code);
    },
    isCheckAll() {
      return (
        this.customerList.length === this.checkedList.length &&
        this.checkedList.length > 0
      );
    },
    ...getters,
  },
  created() {
    console.log(this.$route);
    event.$on("sdnm-radio", this.sdnmRadio);
    this.getDetail();
  },
  beforeDestroy() {
    event.$off("sdnm-radio", this.sdnmRadio);
  },
  data() {
    return {
      remark: "",
      editorConfig: {
        // 如果需要上传功能,找后端小伙伴要服务器接口地址
        serverUrl:
          window.location.href.split("/announcement")[0] +
          "/announcement/ueditor/config",
        // serverUrl: '../../../../static/UEditor/php/controller.php',
        // 你的UEditor资源存放的路径,相对于打包后的index.html
        // UEDITOR_HOME_URL: '../../../../static/UEditor/',
        UEDITOR_HOME_URL: "/lib/ueditor/",
        // 设置编辑器是否自动被内容撑高
        autoHeightEnabled: true,
        // 初始容器高度
        initialFrameHeight: 500,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 关闭自动保存
        // enableAutoSave: false,
        wordCount: true, // 是否开启字数统计
        maximumWords: 5000, // 允许的最大字符数
        toolbars: [
          [
            "fontSize",
            "forecolor",
            "bold",
            "italic",
            "underline",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "insertimage",
            "imageleft",
            "imagecenter",
            "imageright",
            "undo",
            "redo",
            "preview",
          ],
        ],
      },
      typeList: [
        { id: 1, name: "普通消息" },
        { id: 2, name: "公告消息" },
      ],
      customerTypeList: [
        { id: 1, name: "全部客户" },
        { id: 2, name: "部分客户" },
      ],
      type: 1,
      customerType: 1,
      title: "",
      content: "",
      isShow: false,
      customerList: [],
      layer: {
        start: 0,
        length: 10,
        total: 0,
      },
      hq_codes: [],
    };
  },
  methods: {
    getDetail() {
      let $this = this;
      apiPost({
        url: APIs.ANNOUNCEMENT,
        data: {
          action: "detail",
          id: this.$route.params.id,
        },
        success(res) {
          if (res.code === "0") {
            $this.title = res.data.title;
            $this.content = res.data.content;
            $this.type = res.data.type;
            $this.customerType = res.data.company_info.length === 0 ? 1 : 2;
            $this.hq_codes = res.data.company_info.map((el) => el.code);
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    save(status) {
      if (this.title === "") {
        this.setAlert({ msg: "请填写标题" });
        return;
      } else if (this.customerType === 2 && this.hq_codes.length === 0) {
        this.setAlert({ msg: "请填写客户" });
        return;
      }
      let $this = this;
      apiPost({
        url: APIs.ANNOUNCEMENT,
        data: {
          action: "edit",
          title: this.title,
          content: this.content,
          type: this.type,
          status: status,
          hq_codes: this.hq_codes,
          id: this.$route.params.id,
        },
        success(res) {
          $this.setAlert({ msg: res.msg, type: res.code === "0" });
          if (res.code === "0") {
            $this.cancel();
          }
        },
        error(res) {
          $this.setAlert({ msg: "服务器错误" });
        },
      });
    },
    saveLayer() {
      this.hq_codes = this.checkedList;
      this.cancelLayer();
    },
    check(index) {
      this.customerList[index].checked = !this.customerList[index].checked;
    },
    checkAll() {
      if (this.isCheckAll) {
        this.customerList.forEach((el) => {
          el.checked = false;
        });
      } else {
        this.customerList.forEach((el) => {
          el.checked = true;
        });
      }
    },
    choose() {
      this.isShow = true;
      this.getCustomerList();
    },
    sdnmRadio(index, name) {
      if (name === "customerType") {
        this.customerType = this.customerTypeList[index].id;
      } else if (name === "type") {
        this.type = this.typeList[index].id;
      }
    },
    getCustomerList(index = 0) {
      let $this = this;
      this.layer.start = index;
      apiPost({
        url: APIs.COMPANY,
        data: {
          action: "company.list",
          start: this.layer.start,
          length: 9999,
        },
        success(res) {
          if (res.code === "0") {
            $this.customerList = res.data.map((el) => {
              el.checked = $this.hq_codes.includes(el.code);

              return el;
            });
            $this.layer.total = res.total;
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    cancelLayer() {
      this.isShow = false;
    },
    cancel() {
      router.push(URL.ANNOUNCEMENT);
    },
    ...actions,
  },
};
</script>
<style lang="less">
@import url("../../less/common.less");
.new-announcement {
  .mgr-5 {
    margin-right: 5px;
  }
  .mgl-20 {
    margin-left: 20px;
  }

  &.wrapper .content {
    overflow: auto;
    padding-bottom: 70px;
  }
  .form .field {
    display: flex;
    label {
      .dib();
      width: 80px;
    }
    .editor {
      flex: 1;
    }
    .desc {
      .fc(12px, #666);
    }
    a {
      cursor: pointer;
    }
  }
  #layer-content .layer {
    z-index: 1000;
    .layer-container {
      .pd(20px);
    }
    .native-table {
      max-height: 600px;
    }
  }
}
</style>
